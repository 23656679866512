<template>
  <div
    class="activity-card"
    :data-activity-id="activity && activity.id"
    :class="{ 'is-opened': isOpened }">
    <div class="activity-card__head">
      <div class="activity-card__info">
        <div v-if="profiles.length && !isMentor">
          {{ profiles.map((n) => n.title).join(", ") }}
        </div>
        <a
          href="#"
          class="activity-card__title mb-s"
          @click.prevent="isOpened = !isOpened"
          >{{ activityTitle
          }}<span
            v-if="activity.isExtra && activity.stage !== FINAL_STAGE"
            class="extra-level"
            @click.prevent.stop="openHighLevelModal"
            ><span class="extra-level__icon"
              ><BaseIcon
                width=".8em"
                glyph="star" /></span
            ><span class="extra-level__text">Повышенная сложность</span></span
          ></a
        >
        <div class="activity-card__chevron"></div>

        <!-- Для активностей финала с загрузкой работ -->
        <WorkPanel
          v-if="activity.stage === FINAL_STAGE && !isMentor"
          :activity-id="activity.id" />
        <!-- Для любых других активностей -->
        <template v-else>
          <div
            v-if="score"
            class="activity-card__score mb-s">
            <BaseIcon
              glyph="done"
              width="16px"
              class="activity-card__score-icon"></BaseIcon>
            <div>
              Лучший балл: {{ score.score }}
              <div
                v-if="scoreUpdatedText"
                class="activity-card__score-text">
                Обновлено {{ scoreUpdatedText }}
              </div>
            </div>
          </div>

          <div
            v-if="nextDateString"
            class="activity-card__meta">
            {{ nextAttempt.title }} старт {{ nextDateString }}
          </div>
        </template>
      </div>
      <div class="activity-card__control">
        <div v-if="isMentor">
          <BaseButton
            theme="primary-border"
            class="mt-m"
            tag="router-link"
            :to="{
              name: 'mentor-student-activity',
              params: {
                activity_id: activity.id,
                id: studentId,
              },
            }"
            >детализация баллов</BaseButton
          >
        </div>
        <template v-else>
          <div v-if="needTeam">
            <div class="form-error">
              <BaseIcon
                valign="middle"
                glyph="warn" />&nbsp;Необходимо создать
              <br />
              или вступить в команду
            </div>
            <div>
              {{ currentDateString }}
            </div>
          </div>
          <template v-else>
            <div v-if="!isExternal">
              <div v-if="currentAttempt">
                <EnrollButton
                  v-if="!!activity.yacontest"
                  class="activity-card__btn mb-s"
                  :activity="activity"
                  :disabled="!currentAttempt.isActive"
                  :attempt-id="currentAttempt.id"
                  ><span>{{ enrollBtnText }}</span>
                </EnrollButton>
                <div>
                  {{ currentDateString }}
                </div>
              </div>
              <div v-else-if="!attemptsList.length">Расписание уточняется</div>
              <div v-else>Задание завершено</div>
            </div>
            <div v-else>
              <BaseButton
                v-if="activity.external_url"
                tag="a"
                :href="activity.external_url"
                target="_blank"
                rel="noopener noreferrer"
                class="activity-card__btn mb-s mt-s"
                :disabled="!isActiveExternalActivity"
                >{{ enrollBtnText }}</BaseButton
              >
              <div>
                {{ currentDateString }}
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
    <div
      v-if="isOpened"
      class="activity-card__body">
      <div>
        <div
          v-if="profiles.length && isMentor"
          class="activity-card__profiles mb-m">
          {{ profiles.length > 1 ? "Профили" : "Профиль" }}:
          {{ profiles.map((n) => n.title).join(", ") }}
        </div>

        <div
          v-if="activity.yacontest"
          class="mb-m">
          Это задание необходимо решать на платформе Яндекс.Контест
        </div>

        <!-- <div v-if="isJuniorActivity">
          Это задание для участников младшей лиги
        </div> -->

        <div
          v-if="activity.description"
          class="mb-m">
          <div class="mb-xs text-bold">Описание</div>
          <div class="text-pre-wrap">{{ activity.description }}</div>
        </div>

        <div
          v-if="attemptsList.length"
          class="mb-m">
          <div class="mb-xs text-bold">Расписание</div>
          <ul class="m-schedule">
            <li
              v-for="item in attemptsList"
              :key="item.id"
              class="m-schedule__item">
              <div class="m-schedule__title">{{ item.title }}</div>
              <template v-if="item.formatedStart && item.formatedEnd">
                <span class="m-schedule__date">{{ item.formatedStart }}</span>
                &mdash;
                <span class="m-schedule__date">{{ item.formatedEnd }}</span>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import findIndex from "lodash/findIndex";
import EnrollButton from "@/components/EnrollButton";
import { mapGetters } from "vuex";
import { excludeWrongAttempts } from "@/utils/profile";
import WorkPanel from "./WorkPanel";
import ProfieTrackSelectModal from "@/components/user/modals/ProfieTrackSelectModal";
import {
  TEAM_STAGE,
  MODAL_DYNAMIC_DEFAULTS,
  INDIVIDUAL_STAGE,
  FINAL_STAGE,
} from "@/constants";
const SCHEDULE_FORMAT = "dddd D MMMM HH:mm (UTC Z)";

const STAGE_LABELS = {
  [INDIVIDUAL_STAGE]: "индивидуальный этап",
  [TEAM_STAGE]: "командный этап",
  [FINAL_STAGE]: "финал",
};
export default {
  name: "ActivityCard",
  components: {
    EnrollButton,
    WorkPanel,
  },
  props: {
    studentId: {
      type: Number,
    },
    score: {
      type: Object,
    },
    isMentor: {
      type: Boolean,
    },
    activity: {
      type: Object,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpened: this.isOpen,
      enrollBtnText: "Перейти к заданию",
      FINAL_STAGE,
    };
  },
  computed: {
    syncTimeStamp() {
      return this.$store.state.syncTimeStamp;
    },
    ...mapGetters({
      allProfiles: "profile/allProfiles",
      isJunior: "participant/isJunior",
      isStudent: "participant/isStudent",
      selectedList: "participant/selectedList",
    }),
    attemptsList() {
      const { activity } = this;
      if (!activity) return [];
      // если не внешняя и нет попыток
      // if (!activity.external_url && !activity.attempts?.length) return [];

      let list = excludeWrongAttempts(activity.attempts);
      // если попыток нет, то создадим попытку из самой активности
      if (!list?.length) {
        list = [
          {
            start_at: activity.start_at,
            end_at: activity.end_at,
            title: activity.title,
            activity_id: activity.id,
            id: activity.id,
          },
        ];
      }
      return list
        .map((attempt) => {
          return {
            ...attempt,
            formatedStart:
              attempt.start_at &&
              dayjs(attempt.start_at).format(SCHEDULE_FORMAT),
            formatedEnd:
              attempt.end_at && dayjs(attempt.end_at).format(SCHEDULE_FORMAT),
          };
        })
        .sort((a, b) => {
          return dayjs(a.start_at) - dayjs(b.start_at);
        });
    },
    // Текущая попытка
    currentAttempt() {
      const { attemptsList, syncTimeStamp } = this;
      if (!attemptsList) return;
      const current = attemptsList.find((attempt) => {
        return dayjs(attempt.end_at).isAfter(dayjs(syncTimeStamp));
      });
      if (!current) return;
      return {
        ...current,
        // Уже началась
        isActive: dayjs(current.start_at).isBefore(dayjs(syncTimeStamp)),
      };
    },
    // Следующая попытка
    nextAttempt() {
      const { currentAttempt, attemptsList } = this;
      if (!currentAttempt || !attemptsList) return;
      const idx = findIndex(attemptsList, (item) => {
        return item.id === currentAttempt.id;
      });
      return attemptsList[idx + 1];
    },
    currentDateString() {
      const { currentAttempt, isExternal, activity, syncTimeStamp } = this;
      if (isExternal) {
        if (!activity.start_at || !activity.end_at) {
          return "Расписание уточняется";
        }
        if (dayjs(activity.start_at).isAfter(syncTimeStamp)) {
          return `Начнется ${dayjs(this.syncTimeStamp).to(activity.start_at)}`;
        } else if (dayjs(activity.end_at).isBefore(syncTimeStamp)) {
          return "Задание завершено";
        } else {
          return `Закончится ${dayjs(this.syncTimeStamp).to(activity.end_at)}`;
        }
      } else {
        if (!currentAttempt) return;
        if (currentAttempt.isActive) {
          return `Закончится ${dayjs(this.syncTimeStamp).to(
            currentAttempt.end_at
          )}`;
        }
        return `Начнется ${dayjs(this.syncTimeStamp).to(
          currentAttempt.start_at
        )}`;
      }
    },
    nextDateString() {
      const { nextAttempt, currentAttempt } = this;
      if (!nextAttempt || !currentAttempt.isActive) return;
      if (dayjs(this.syncTimeStamp).isBefore(nextAttempt.start_at)) {
        return dayjs(this.syncTimeStamp).to(nextAttempt.start_at);
      }
      return undefined;
    },
    profiles() {
      const { isMentor } = this;
      if (!this.activity?.id) return;
      if (isMentor) {
        return this.allProfiles.filter((profile) =>
          profile.activities
            .map((activity) => activity.id)
            .includes(this.activity.id)
        );
      }

      const selected = this.selectedList.map((n) => Number(n.profile_id));
      return this.allProfiles.filter((profile) => {
        if (!selected.includes(profile.id)) return false;
        return profile.activities
          .map((activity) => activity.id)
          .includes(this.activity.id);
      });
    },
    scoreUpdatedText() {
      const { score } = this;
      if (!score?.updated_at) return undefined;
      return dayjs(this.syncTimeStamp).to(score.updated_at);
    },
    isExternal() {
      return !!this.activity?.external_url;
    },
    // isJuniorActivity() {
    //   const { activity } = this;
    //   if (!activity) return false;
    //   const track = activity.tracks?.[0];
    //   return track?.alias === JUNIOR_ALIAS;
    // },
    isActiveExternalActivity() {
      const { isExternal, activity, syncTimeStamp } = this;
      if (!isExternal || !activity) return false;
      if (!activity.start_at || !activity.end_at) return false;
      const now = dayjs(syncTimeStamp);
      if (now.isBefore(activity.start_at)) return false;
      return now.isBetween(activity.start_at, activity.end_at);
    },
    isTeamStageActivity() {
      const { activity } = this;
      return activity?.stage === TEAM_STAGE;
    },
    needTeam() {
      const { activity, isTeamStageActivity } = this;
      return (
        !activity.individual_activity &&
        isTeamStageActivity &&
        !activity.hasTeam
      );
    },
    activityTitle() {
      const { activity } = this;
      if (!activity) return;
      let title = activity.title;
      if (STAGE_LABELS[activity?.stage])
        title += `, ${STAGE_LABELS[activity?.stage]}`;
      return title;
    },
  },
  methods: {
    openHighLevelModal() {
      this.$modal.show(ProfieTrackSelectModal, {}, MODAL_DYNAMIC_DEFAULTS);
    },
  },
};
</script>

<style lang="less" scoped>
.activity-link {
  position: relative;
  z-index: 1;
}
.q-circle {
  display: inline-block;
  vertical-align: middle;
  font-size: 1em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: @primary-color;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 0.8;
  margin-right: 0.45em;
  margin-top: -0.25em;

  &::before {
    content: "?";
    line-height: 1;
    font-size: 0.7em;
  }
}
.extra-level {
  display: inline-flex;
  align-items: center;
  font-size: 0.85em;
  flex-flow: row nowrap;
  color: @link-color;
  font-weight: bold;
  line-height: 1;
  margin-left: 0.6em;
  vertical-align: middle;
  position: relative;
  z-index: 1;

  &__text {
    border-bottom: 1px dotted fade(@link-color, 80%);
  }

  &:hover &__text {
    border-bottom-color: transparent !important;
  }

  &__icon {
    width: 1.2em;
    height: 1.2em;
    text-align: center;
    border-radius: 50%;
    color: @success;
    background-color: @link-color;
    color: #fff;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0.25em;
    line-height: 1;
  }
}
.activity-card {
  background-color: #fff;
  overflow: hidden;
  border-radius: @radius-xl;
  box-shadow: @shadow-default;
  transition: box-shadow 0.3s;
  .fz(14);

  &:hover {
    box-shadow: @shadow-hover;
  }

  &__head {
    padding: 24px 24px 24px 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0px;
    position: relative;

    .media-max-sm({
      display: block;
    });
    .media-max-xs({
      padding: 12px 12px 12px 40px;
    });
  }

  &__label {
    z-index: 1;
    position: relative;
  }

  &__info {
    min-width: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    .fz(14);
  }

  &__score {
    display: flex;
    align-items: flex-start;
    line-height: 1.2;

    &-icon {
      margin-right: 0.4em;
      color: @success;
      flex-grow: 0;
      margin-top: 0px;
    }

    &-text {
      opacity: 0.75;
    }
  }

  &__meta {
    margin-top: auto;
  }

  &__control {
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    min-width: 180px;

    & ::v-deep .base-btn {
      min-width: 180px;
    }
    .media-max-sm({
      text-align: left;
      margin-top: 5px;
    });
  }

  &__title {
    font-weight: bold;
    color: inherit;
    transition: color 0.3s;
    text-decoration: none;
    margin-top: 2px;
    .fz(18);

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      color: @link-color;
    }
  }

  &__body {
    padding: 24px;
    background-color: #f6f8fb;
    .fz(16);
  }

  &__profile {
    display: inline-block;
    margin-right: 15px;

    &:first-child {
      margin-left: 5px;
    }
  }

  &__chevron {
    position: absolute;
    width: 12px;
    height: 12px;
    border: 1px solid;
    transform: rotateZ(45deg);
    border-top: 0;
    border-left: 0;
    top: 28px;
    left: 20px;
    transition: color 0.3s, transform 0.3s;
    transform-origin: center center;
    .media-max-xs({
      left: 14px;
      top: 15px;
    });
  }

  &__title:hover + &__chevron {
    color: @link-color;
  }

  &.is-opened &__chevron {
    transform: rotateZ(225deg);
  }

  &__btn-text {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: 180px;
    text-overflow: ellipsis;
  }
}

.m-schedule {
  list-style-type: none;

  &__date {
    font-size: 0.8em;
  }

  &__item {
    padding-left: 20px;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 1.2;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: fade(@link-color, 10%);
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 1px;
      width: 0.5em;
      height: 0.5em;
      background-color: lighten(@link-color, 30%);
      // border-radius: 50%;
      transform: translate(-50%, -50%) rotateZ(-45deg);
      // clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    }

    &:first-child::before {
      top: 50%;
    }

    &:last-child::before {
      bottom: 50%;
    }
  }
}
</style>
